<template>
  <catalog-form
    ref="catalog-form"
    :service="service"
  />
</template>
<script>
import catalogForm from '@/views/main/catalog/components/catalogForm.vue'
import NonStockService from '@/views/main/catalog/service/NonStockService'

export default {
  components: {
    catalogForm,
  },
  methods: {
    showConfirmationModal(next) {
      if (this.$refs['catalog-form']) this.$refs['catalog-form'].showConfirmationModal(next)
    },
  },
  setup() {
    const service = new NonStockService()
    return {
      service,
    }
  },
}
</script>
