<template>
  <sub-menu-component :menu-items="menuItems">
    <div>
      <portal to="body-top">
        <p class="text-center font-weight-bolder font-medium-5 my-1">
          {{ $t('Add a Catalog Item') }}
        </p>
      </portal>
      <catalog-create-tabs class="mb-1 w-100" />
      <skeleton v-if="isLoading" />
      <div v-else>
        <stock-component-form
          v-if="isActive(CATALOG_TYPE_COMPONENT)"
          ref="formRef"
        />
        <stock-container-form
          v-if="isActive(CATALOG_TYPE_CONTAINER)"
          ref="formRef"
        />
        <stock-product-form
          v-if="isActive(CATALOG_TYPE_PRODUCT)"
          ref="formRef"
        />
        <service-non-stock-form
          v-if="isActive(CATALOG_TYPE_NON_STOCK)"
          ref="formRef"
        />
      </div>
    </div>
  </sub-menu-component>
</template>

<script>
import {
  CATALOG_TYPE_PRODUCT,
  CATALOG_TYPE_CONTAINER,
  CATALOG_TYPE_COMPONENT,
  CATALOG_TYPE_NON_STOCK,
} from '@/views/main/catalog/catalogType'
import BaseCatalogService from '@/views/main/catalog/service/BaseCatalogService'
import CatalogCreateTabs from '../components/CatalogTabs/CatalogTabs.vue'
import ServiceNonStockForm from '../components/CatalogForms/ServiceNonStockForm.vue'
import StockComponentForm from '../components/CatalogForms/StockComponentForm.vue'
import StockContainerForm from '../components/CatalogForms/StockContainerForm.vue'
import StockProductForm from '../components/CatalogForms/StockProductForm.vue'
import Skeleton from '../view/general/skeletons/skeleton.vue'

export default {
  name: 'CatalogCreate',
  components: {
    CatalogCreateTabs,
    StockProductForm,
    StockComponentForm,
    StockContainerForm,
    ServiceNonStockForm,
    Skeleton,
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.formRef) {
      this.$refs.formRef.showConfirmationModal(next)
    } else {
      next()
    }
  },
  data() {
    return {
      defaultSku: null,
      loading: true,
      defaultSettings: null,
      isLoading: false,
    }
  },
  computed: {
    catalogForm() {
      return this.$store.state[this.MODULE_NAME].catalogForm
    },
    catalog() {
      return this.$store.state[this.MODULE_NAME].catalog
    },
    menuItems() {
      return new BaseCatalogService().updateMenu('home-catalog-create', this.catalogForm.type_id, {}, true)
    },
  },
  created() {
    this.isLoading = true
    this.$store.commit(`${this.MODULE_NAME}/RESET_CATALOG`)
    this.$store.commit(`${this.MODULE_NAME}/SET_CATALOG_FORM`, {
      type_id: this.catalog.type_id,
      categories: [],
      tags: [],
    })
    this.$store.dispatch(`${this.SETTINGS_CATALOG_MODULE_NAME}/getDefaultSettings`, {}).then(res => {
      this.loading = true
      const { data } = res

      const mappedObj = {}

      data.forEach(item => {
        if (item.key !== undefined) {
          mappedObj[item.key] = JSON.parse(item.value) // ==> parsing string true/ false to boolean
        }
      })
      this.defaultSettings = mappedObj
    }).catch(err => {
      this.errorNotification(this, err)
    }).finally(() => {
      this.loading = false

      const initialData = {
        sku: '',
        defaultSku: this.defaultSku,
        turnaround_time: this.defaultSettings.catalog_defaults_default_turnaround_time,
        status: this.defaultSettings.catalog_defaults_is_active,
        type_id: this.catalog.type_id ?? CATALOG_TYPE_PRODUCT,
        can_be_transferred: this.defaultSettings.catalog_defaults_default_fulfillment_rules_can_be_transferred,
        requires_container: this.defaultSettings.catalog_defaults_default_fulfillment_rules_requires_a_container,
        serialized: this.defaultSettings.catalog_defaults_by_default_is_serialized,
        replacement_cost: this.defaultSettings.catalog_price_costing_default,
        //  asset Rules
        needs_individual_asset_id: this.defaultSettings.catalog_defaults_default_assets_rules_needs_individual_asset_id,
        requires_individual_asset_price: this.defaultSettings.catalog_defaults_default_assets_rules_requires_individual_asset_price,
        can_be_procured: this.defaultSettings.catalog_defaults_default_assets_rules_can_be_procured,
        can_be_rented_individually: this.defaultSettings.catalog_defaults_default_assets_rules_can_be_rented,
        can_be_sub_rented: this.defaultSettings.catalog_defaults_default_assets_rules_can_be_sub_rented,
        can_be_sold: this.defaultSettings.catalog_defaults_default_assets_rules_can_be_sold,
        categories: [],
        tags: [],

        // preparation times
        pick_time_per_item: this.defaultSettings.catalog_defaults_default_pick_time_per_item,
        pack_time_per_item: this.defaultSettings.catalog_defaults_default_pack_time_per_item,
        receive_time_per_item: this.defaultSettings.catalog_defaults_default_receive_time_per_item,
        put_back_time_per_item: this.defaultSettings.catalog_defaults_default_put_back_time_per_item,
      }

      this.$store.commit(`${this.MODULE_NAME}/SET_CATALOG_FORM`, initialData)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CATALOG_FORM_CLONE`, initialData)
      this.isLoading = false
    })
  },
  methods: {
    isActive(type) {
      return this.catalogForm.type_id === type
    },
  },
  setup() {
    const MODULE_NAME = 'catalogs'
    const SETTINGS_CATALOG_MODULE_NAME = 'settings-catalog-defaults'
    const MODULE_NAME_CLONE = 'cloneData'

    return {
      MODULE_NAME,
      SETTINGS_CATALOG_MODULE_NAME,
      CATALOG_TYPE_NON_STOCK,
      CATALOG_TYPE_PRODUCT,
      CATALOG_TYPE_COMPONENT,
      CATALOG_TYPE_CONTAINER,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
